/*
@font-face {
    font-family: "";
    src: url("") format("woff2"),
        url("") format("woff");
    font-weight: 400;
    font-style: normal;
}
*/

%ff-regular {
    font-family: 'NewsGothicNo2W01-Medium_710407';
}

%fs-body {
    font-size: $fs-base;
    line-height: $lh-base;

    @media (min-width: $small) {
        font-size: 1.1em;
    }

    @media (min-width: $xlarge) {
        font-size: 1.2em;
    }
}
