html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

#breakpoint, #edit-page {
  z-index: 1000;
  text-align: center;
  color: #fff;
  background: #ff00004d;
  height: 35px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 36px;
  display: block;
  position: fixed;
  bottom: 10px;
}

@media print {
  #breakpoint, #edit-page {
    display: none;
  }
}

#breakpoint {
  width: 35px;
  right: 70px;
}

#breakpoint:after {
  content: "?";
}

@media (width >= 20em) {
  #breakpoint:after {
    content: "XXS";
  }
}

@media (width >= 30em) {
  #breakpoint:after {
    content: "XS";
  }
}

@media (width >= 40em) {
  #breakpoint:after {
    content: "S";
  }
}

@media (width >= 48em) {
  #breakpoint:after {
    content: "M";
  }
}

@media (width >= 64em) {
  #breakpoint:after {
    content: "L";
  }
}

@media (width >= 80em) {
  #breakpoint:after {
    content: "XL";
  }
}

@media (width >= 90em) {
  #breakpoint:after {
    content: "XXL";
  }
}

#edit-page {
  width: 50px;
  text-decoration: none;
  transition: background .15s;
  right: 10px;
}

#edit-page:hover {
  background: #ff0000d9;
  text-decoration: none;
}

h1 a {
  color: inherit;
  text-decoration: none;
}

#init, #close-about {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-transform: inherit;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

#init::-moz-focus-inner {
  border: 0;
  padding: 0;
}

#close-about::-moz-focus-inner {
  border: 0;
  padding: 0;
}

body {
  font-family: NewsGothicNo2W01-Medium_710407;
  font-size: 1em;
  line-height: 1.25;
}

@media (width >= 40em) {
  body {
    font-size: 1.1em;
  }
}

@media (width >= 80em) {
  body {
    font-size: 1.2em;
  }
}

html, body {
  height: 100%;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

.body-inner {
  height: inherit;
  opacity: 0;
  transition: opacity 4s;
  overflow: hidden;
}

.is-visible .body-inner {
  opacity: 1;
}

a {
  color: #9b9b9b;
  text-decoration: none;
}

p {
  margin-bottom: 1.25em;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

::selection {
  background-color: #ffffff40;
}

header, footer {
  text-align: center;
  text-transform: uppercase;
  height: 15%;
  padding: 0 6.5vw;
}

h1, #time {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

h1 span, #time span {
  transition: letter-spacing .15s;
}

main {
  height: 70%;
  position: relative;
}

audio {
  display: none;
}

video {
  visibility: hidden;
  width: auto;
  height: 100%;
}

.half {
  height: 100%;
}

.half.left, .half.right {
  z-index: 1;
  position: absolute;
  top: 0;
}

.half.left {
  width: 124.444vh;
  right: calc(50% - 1px);
}

.half.right {
  width: 50%;
  min-width: 124.444vh;
  left: 50%;
}

#buffer {
  visibility: hidden;
}

#intro, #about {
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
  background-color: #000;
  width: 100%;
  height: 100%;
  font-size: .95em;
  transition: opacity .5s, visibility .5s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
}

#intro.is-visible, #about.is-visible {
  opacity: 1;
  visibility: visible;
}

#intro > .inner, #about > .inner {
  max-width: 33em;
  margin: auto;
  padding: 1.25em 1em;
}

#overlay {
  z-index: 4;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  background-color: #fff0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#about {
  z-index: 10;
}

#intro {
  z-index: 5;
  text-align: center;
}

.legacy-browser {
  color: red;
  display: none;
}

.no-canvas .legacy-browser, .no-classlist .legacy-browser {
  display: block;
}

#init, #close-about {
  color: #000;
  background-color: #fff;
  margin-bottom: 1.25em;
  padding: .5em 1em;
}

.no-canvas #init, .no-classlist #init {
  display: none;
}

.tutorial {
  color: #9e7ae4;
  margin-bottom: .625em;
}

#close-about {
  margin: 0 auto;
  display: block;
}

/*# sourceMappingURL=index.b6e6040c.css.map */
