header,
footer {
    padding: 0 6.5vw;
    height: $header-height;
    text-align: center;
    text-transform: uppercase;
}

h1,
#time {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;

    span {
        transition: letter-spacing 150ms;
    }
}

h1 {
    a {
        @extend %link-reset;
    }

    /*
    .is-playing & {
        cursor: pointer;

        &:after {
            content: "";
            display: block;
            position: relative;
            top: 0;
            border-bottom: 1px solid transparent;
            height: 1px;
            transition: border-color 250ms ease, top 250ms ease;
        }

        &:hover {

            &:after {
                top: 6px;
                border-color: $white;
            }
        }
    }
    */
}

main {
    position: relative;
    height: calc(100% - #{$header-height * 2});
}

audio {
    display: none;
}

video {
    height: 100%;
    width: auto;
    visibility: hidden;
}

canvas {
    //width: 100%;
    //height: 100%;
}

.half {
    //position: relative;
    height: 100%;

    &.left,
    &.right {
        position: absolute;
        z-index: 1;
        top: 0;
    }

    &.left {
        right: calc(50% - 1px);
        //min-width: calc(70vh * 1280 / 720);
        width: calc(
            70vh * 1280 / 720
        ); // TODO: better solution? also use $header-height var?
    }

    &.right {
        left: 50%;
        width: 50%;
        min-width: calc(
            70vh * 1280 / 720
        ); // TODO: better solution? also use $header-height var?
    }
}

#buffer {
    visibility: hidden;
}

#intro,
#about {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms ease, visibility 500ms ease;
    font-size: 0.95em;

    &.is-visible {
        opacity: 1;
        visibility: visible;
    }

    > .inner {
        margin: auto;
        max-width: 33em;
        padding: $blank-line $gutter;
    }
}

#overlay {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
        white,
        0
    ); // IE10 workaround to make element clickable
    -webkit-touch-callout: none; // Hide text loupe in iOS
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // iOS
    -webkit-tap-highlight-color: transparent; // Android
    cursor: pointer;
}

#about {
    z-index: 10;
}

#intro {
    z-index: 5;
    text-align: center;
}

.legacy-browser {
    display: none;
    color: $red;

    .no-canvas &,
    .no-classlist & {
        display: block;
    }
}

#init,
#close-about {
    @extend %button-reset;
    margin-bottom: $blank-line;
    color: $black;
    background-color: $white;
    padding: 0.5em 1em;
}

#init {
    // button

    .no-canvas &,
    .no-classlist & {
        display: none;
    }
}

.tutorial {
    margin-bottom: calc($blank-line/2);
    color: $purple;
}

#close-about {
    // button
    display: block;
    margin: 0 auto;
}
