html,
body {
    height: 100%;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    @extend %ff-regular;
    @extend %fs-body;
    color: $white;
    background-color: $black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.body-inner {
    height: inherit;
    overflow: hidden;
    opacity: 0;
    transition: opacity 4000ms ease;

    .is-visible & {
        opacity: 1;
    }
}

a {
    color: $gray;
    text-decoration: none;
}

p {
    margin-bottom: $blank-line;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

::selection {
    background-color: rgba($white, 0.25);
}
