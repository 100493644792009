// Clear floats
@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

%link-reset {
    color: inherit;
    text-decoration: none;
}

%button-reset {
    margin: 0;
    border: none;
    padding: 0;
    color: inherit;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    cursor: pointer;

    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }
}
