#breakpoint,
#edit-page {
    position: fixed;
    display: block;
    z-index: 1000;
    bottom: 10px;
    height: 35px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    background: rgba(red, 0.3);

    @media print {
        display: none;
    }
}

// Display breakpoint on page
#breakpoint {
    right: 70px;
    width: 35px;

    &:after {
        content: '?';
        @media (min-width: $xxsmall) {
            content: 'XXS';
        }
        @media (min-width: $xsmall) {
            content: 'XS';
        }
        @media (min-width: $small) {
            content: 'S';
        }
        @media (min-width: $medium) {
            content: 'M';
        }
        @media (min-width: $large) {
            content: 'L';
        }
        @media (min-width: $xlarge) {
            content: 'XL';
        }
        @media (min-width: $xxlarge) {
            content: 'XXL';
        }
    }
}

// Edit link of processwire cms
#edit-page {
    right: 10px;
    width: 50px;
    transition: background 0.15s ease;
    text-decoration: none;

    &:hover {
        background: rgba(red, 0.85);
        text-decoration: none;
    }
}
