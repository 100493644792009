// Colors
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$gray: rgb(155, 155, 155);
$red: red;
$purple: #9e7ae4;

// Breakpoints
$xxsmall: 20em; // 320px
$xsmall: 30em; // 480px
$small: 40em; // 640px
$medium: 48em; // 768px
$large: 64em; // 1024px
$xlarge: 80em; // 1280px
$xxlarge: 90em; // 1440px

// Typography
$fs-base: 1em;
$lh-base: 1.25;

// Spacing
//$max-width: 100rem; // 1600px
$blank-line: $lh-base * 1em;
$gutter: 1em;
$header-height: 15%;
